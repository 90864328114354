import React from "react";
import { Link, withRouter } from "react-router-dom";
import { Menu } from "antd";
import {
  DashboardOutlined,
  ShopOutlined,
  UsergroupAddOutlined,
  PercentageOutlined,
} from "@ant-design/icons";
import { useStore } from "../../store/store";

const SideNavMenu = ({ location }) => {
  const { state } = useStore();

  return (
    <Menu
      theme="dark"
      mode="inline"
      selectedKeys={[location.pathname || "/dossiers"]}
      defaultSelectedKeys={["/dossiers"]}
    >
      <Menu.Item key="/dossiers">
        <Link to="/dossiers">
          <ShopOutlined />
          <span className="nav-text">Dossiers</span>
        </Link>
      </Menu.Item>

      <Menu.Item key="/taux">
        <Link to="/taux">
          <PercentageOutlined />
          <span className="nav-text">Taux</span>
        </Link>
      </Menu.Item>

      <Menu.Item key="/parties">
        <Link to="/parties">
          <UsergroupAddOutlined />
          <span className="nav-text">Parties</span>
        </Link>
      </Menu.Item>

      {state && state.role === "Règles" && (
        <Menu.Item key="/regles">
          <Link to="/regles">
            <DashboardOutlined />
            <span className="nav-text">Règles</span>
          </Link>
        </Menu.Item>
      )}
      {state && state.role === "Admin" && (
        <Menu.Item key="/users">
          <Link to="/users">
            <DashboardOutlined />
            <span className="nav-text">Utilisateurs</span>
          </Link>
        </Menu.Item>
      )}
    </Menu>
  );
};

export default withRouter(SideNavMenu);
