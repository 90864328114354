import React from "react";
import { Modal, Card } from "antd";

const EcranAttente = ({ visible }) => (
  <Modal visible={visible} footer={null} closable={false}>
    <Card
      style={{ margin: "auto" }}
      bordered={false}
      cover={<img alt="scrooge" src="/picsou.gif" />}
    ></Card>
  </Modal>
);

export default EcranAttente;
