import React from "react";
import { Button } from "antd";
import { FileExcelOutlined } from "@ant-design/icons";

// Téléchargement du CSV
const download = (text, fileName) => {
  var BOM = "\uFEFF";
  const link = document.createElement("a");
  link.setAttribute(
    "href",
    `data:text/csv;charset=utf-8,${BOM + encodeURIComponent(text)}`
  );
  link.setAttribute("download", fileName);
  link.style.display = "none";
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

const ExportCSV = ({ dataSource, columns, libelle }) => {
  const genereFichierCsv = () => {
    const header = columns.map((c) => c.title).join(";") + "\n";
    const data = dataSource
      .map((ligne, i) =>
        columns
          .map((colonne) => {
            if (colonne.export) {
              return colonne.export(ligne);
            }
            return ligne[colonne.dataIndex];
          })
          .join(";")
      )
      .join("\n");
    download(header + data, "export.csv");
  };

  return (
    <Button
      style={{ marginLeft: "1rem" }}
      icon={<FileExcelOutlined />}
      onClick={() => genereFichierCsv()}
    >
      {libelle}
    </Button>
  );
};

export default ExportCSV;
