import React from "react";
import { useStore } from "../../store/store";
import { useJsqel } from "../../api/jsqel";
import { Row, Col, Button, message } from "antd";
import { useEvent } from "../../api/cqrs";

const headerStyleLogo = {
  color: "white",
  maxWidth: "200px",
  paddingLeft: "1rem",
};

const headerStyleUser = {
  color: "white",
  padding: "1rem",
};

const headerStyleLogoutButton = {
  marginRight: "1rem",
};

const HeaderContent = () => {
  useJsqel("auth/islogged", {
    sendItNow: true,
    callback: ({ results }) =>
      results && dispatch({ type: "UPDATE", payload: results[0] }),
  });
  const { state, dispatch } = useStore();

  const handleLogout = (e) => dispatch({ type: "LOGOUT" }); // Clear token

  useEvent(() => message.success("Mise à jour effectuée !"), ["JSQEL_SUCCESS"]);
  useEvent(
    (erreur) => message.error("Erreur lors de la mise à jour :" + erreur),
    ["JSQEL_ERROR"]
  );

  return (
    <Row align="middle" type="flex" justify="space-between">
      <Col>
        <h1 style={headerStyleLogo}>Scrooge</h1>
      </Col>
      <Col flex="grow"></Col>

      <Col>
        <span style={headerStyleUser}>Logged as {state.username} </span>
        <Button onClick={handleLogout} style={headerStyleLogoutButton}>
          Log out
        </Button>
      </Col>
    </Row>
  );
};

export default HeaderContent;
