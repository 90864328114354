import React from "react";
import { Row, Col, Card, Divider } from "antd";

const Titre = ({ libelle, niveau }) => {
  const couleur = niveau === 1 ? "rgba(254,181,8,1)" : "rgba(254,255,135,1)";

  const style = {
    padding: "0.5rem",
    width: "100%",
    backgroundColor: couleur,
    display: "block",
    fontWeight: "bold",
    marginBottom: "0.5rem",
  };

  return (
    <Row gutter={[16, 16]}>
      <div style={style}>{libelle}</div>
    </Row>
  );
};

export default Titre;
