import React from "react";
import { Button } from "antd";
import { PrinterOutlined } from "@ant-design/icons";

const imprimerCadre = (c) => {
  const leCadre = document.getElementById(c);
  if (leCadre && leCadre.innerHTML) {
    const leContenu = leCadre.innerHTML;
    var a = window.open("", "", "height=800, width=600");
    a.document.write("<!DOCTYPE html><head>");
    a.document.write(
      '<link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/antd/4.9.2/antd.compact.min.css" crossorigin="anonymous" />'
    );
    a.document.write("</head><body >");
    a.document.write(leContenu);
    a.document.write("</body></html>");
    a.document.close();
    //a.print();
  }
};

const BoutonImprimer = ({ idCadre }) => (
  <Button icon={<PrinterOutlined />} onClick={() => imprimerCadre(idCadre)} />
);

export default BoutonImprimer;
