import React from "react";
import { useCommand } from "../../api/cqrs";
import { Button, Popconfirm } from "antd";

const SuppressionConfirmee = ({ api, id, ...rest }) => {
  const supprimer = useCommand(api, { id });

  return (
    <Popconfirm
      title="Etes-vous sûr de vouloir supprimer cet élément ?"
      onConfirm={() => supprimer()}
      okText="Oui"
      cancelText="Non"
    >
      <Button {...rest}>Supprimer</Button>
    </Popconfirm>
  );
};

export default SuppressionConfirmee;
