import React from "react";
import { Link } from "react-router-dom";
import { useQuery } from "../../api/cqrs";
import { Card, Table, Space, Button } from "antd";
import EditionDossier from "./EditionDossier";
import NouvelleSimulation from "./NouvelleSimulation";
import SuppressionConfirmee from "../crud/SuppressionConfirmee";
import ClonerSimulation from "./ClonerSimulation";
import EditionSimulation from "./EditionSimulation";
import { stringSorter } from "../helpers/sorter";

const Dossiers = () => {
  const dossiers = useQuery("dossiers/list", {}, ["JSQEL_SUCCESS"]);
  const dossiersEtSimulations = dossiers.results.map((dossier) => ({
    key: dossier.id,
    type: "dossier",
    dossier: dossier,
    libelle: dossier.libelle,
    cree_le: dossier.cree_le,
    children: dossier.simulations,
  }));

  const colonnesDossiers = [
    {
      title: "Nom",
      dataIndex: "libelle",
    },
    {
      title: "Créé le",
      dataIndex: "cree_le",
      render: (value) => value.format("DD/MM/YYYY"),
    },
    {
      title: "Type",
      dataIndex: ["dossier", "type_creances", "libelle"],
    },
    {
      title: "Créancier",
      dataIndex: ["dossier", "creancier", "nom"],
      sorter: stringSorter("dossier.creancier.nom"),
    },
    {
      title: "Débiteur",
      dataIndex: ["dossier", "debiteur", "nom"],
      sorter: stringSorter("dossier.debiteur.nom"),
    },
    {
      dataIndex: "id",
      render: (value, record) =>
        record.type === "dossier" ? (
          <Space>
            <NouvelleSimulation dossier={record.dossier} />
          </Space>
        ) : (
          <Space>
            <Link to={`/simulations/${record.id}`}>
              <Button type="primary" shape="round">
                Voir
              </Button>
            </Link>
            <ClonerSimulation simulation_id={record.id} />
          </Space>
        ),
    },
    {
      dataIndex: "id",
      render: (value, record) =>
        record.type === "dossier" ? (
          <Space>
            <EditionDossier dossier={record.dossier} libelle="Modifier" />
            <SuppressionConfirmee
              type="link"
              danger
              api="dossiers/delete"
              id={record.key}
            />
          </Space>
        ) : (
          <Space>
            <EditionSimulation simulation={record} />
            <SuppressionConfirmee
              type="link"
              danger
              api="simulations/delete"
              id={record.id}
            />
          </Space>
        ),
    },
  ];

  return (
    <Card title="Gestion des dossiers">
      <Table
        dataSource={dossiersEtSimulations}
        columns={colonnesDossiers}
        loading={dossiers.loading}
      />
      <EditionDossier libelle="Nouveau dossier" />
    </Card>
  );
};
export default Dossiers;
