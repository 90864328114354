import React from "react";
import { Row } from "antd";
import { useJsqel } from "../../api/jsqel";

const formatterValeurParametre = (parametre, valeur, taux) => {
  if (parametre.est_un_taux) {
    const leTaux = taux.find((t) => t.id === parseInt(valeur));
    if (leTaux) {
      return leTaux.libelle;
    }
  }
  return valeur;
};

const ReglesSpecifiques = ({ evenementsDeLaSimulation }) => {
  const [{ results: taux }] = useJsqel("taux/liste_taux", { sendItNow: true });

  // On recherche tous les paramètres des événements qui sont des taux définis
  const valeursParametresEvenementQuiSontDesTaux = [];
  for (let evenement of evenementsDeLaSimulation) {
    if (evenement.valeurs_parametres_evenements) {
      for (let valeurParametre of evenement.valeurs_parametres_evenements) {
        if (
          valeurParametre.parametres.est_un_taux &&
          valeurParametre.valeur !== "0"
        ) {
          valeursParametresEvenementQuiSontDesTaux.push({
            libelle: evenement.nom,
            valeur: formatterValeurParametre(
              valeurParametre.parametres,
              valeurParametre.valeur,
              taux
            ),
          });
        }
      }
    }
  }

  return (
    <>
      <Row gutter={[16, 16]}>
        <b>Taux appliqués :</b>
      </Row>
      <Row gutter={[16, 16]}>
        <ul>
          {valeursParametresEvenementQuiSontDesTaux.map((valeurParametre) => (
            <li key={valeurParametre.libelle}>
              {valeurParametre.libelle} : {valeurParametre.valeur}
            </li>
          ))}
        </ul>
      </Row>
    </>
  );
};

export default ReglesSpecifiques;
