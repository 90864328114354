import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Card, Table, Button } from "antd";
import { useQuery } from "../../api/cqrs";
import SuppressionConfirmee from "../crud/SuppressionConfirmee";
import EditionFormule from "./EditionFormule";
import DetailTaux from "./DetailTaux";

const Taux = () => {
  const taux = useQuery("taux/liste_taux", {}, ["JSQEL_SUCCESS"]);
  const [tauxEdite, setTauxEdite] = useState(null);

  const colonnes = [
    { title: "Libellé", dataIndex: "libelle" },
    {
      title: "Visibilité",
      dataIndex: "prive",
      render: (value) => (value ? "Privé" : "Public"),
    },
    {
      title: "Type",
      dataIndex: "calcule",
      render: (value) => (value ? "Calculé" : "Référence"),
    },
    {
      title: "Nb de valeurs",
      dataIndex: "nb_valeurs",
      render: (value, record) => (record.calcule ? "N.S" : value),
    },
    {
      dataIndex: "id",
      render: (value, record) =>
        record.calcule && (
          <Button type="link" onClick={() => setTauxEdite(record)}>
            Modifier
          </Button>
        ),
    },
    {
      dataIndex: "id",
      render: (value, record) =>
        record.calcule && (
          <SuppressionConfirmee
            type="link"
            danger
            api="taux/supprime_taux_calcule"
            id={record.id}
          />
        ),
    },
  ];

  return (
    <Card
      title="Gestion des taux"
      extra={
        <Link to="/taux/nouveau">
          <Button onClick={() => setTauxEdite({})}>
            Créer un taux calculé
          </Button>
        </Link>
      }
    >
      <Table
        dataSource={taux.results}
        columns={colonnes}
        rowKey="id"
        expandable={{
          expandedRowRender: (record) => <DetailTaux idTaux={record.id} />,
          rowExpandable: (record) => record.id && !record.calcule,
        }}
      />
      <EditionFormule
        key={
          tauxEdite && tauxEdite.id
            ? "formule_" + tauxEdite.id
            : "nouvelle_formule"
        }
        record={tauxEdite}
        onFinish={() => setTauxEdite(null)}
      />
    </Card>
  );
};

export default Taux;
