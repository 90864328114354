import React from "react";
import { Row } from "antd";
import { useJsqel } from "../../api/jsqel";

const formatterValeurParametre = (parametre, valeur, taux) => {
  if (parametre.est_un_taux) {
    const leTaux = taux.find((t) => t.id === parseInt(valeur));
    if (leTaux) {
      return leTaux.libelle;
    } else {
      return null; // inutile d'afficher un taux "non spécifié"
    }
  }
  return valeur;
};

const formatterValeursParametresRegles = (valeursParametresRegles, taux) => {
  return valeursParametresRegles
    .map((valeurParametre) => ({
      libelle: valeurParametre.parametres.libelle,
      valeur: formatterValeurParametre(
        valeurParametre.parametres,
        valeurParametre.valeur,
        taux
      ),
    }))
    .filter((LibelleEtValeur) => LibelleEtValeur.valeur);
};

const ReglesGeneriques = ({
  valeursParametresReglesSimulation,
  valeursParametresReglesCreancier,
}) => {
  const valeursParametresRegles = [
    ...valeursParametresReglesSimulation,
    ...valeursParametresReglesCreancier,
  ];
  const [{ results: taux }] = useJsqel("taux/liste_taux", { sendItNow: true });

  const libellesEtValeurs = formatterValeursParametresRegles(
    valeursParametresRegles,
    taux
  );

  return (
    <Row gutter={[16, 16]}>
      <ul>
        {libellesEtValeurs.map((libelleEtValeur, index) => (
          <li key={libelleEtValeur.libelle + "-" + index}>
            {libelleEtValeur.libelle} : {libelleEtValeur.valeur}
          </li>
        ))}
      </ul>
    </Row>
  );
};

export default ReglesGeneriques;
