import React, { useState } from "react";
import { useParams, useHistory } from "react-router-dom";
import { Tabs, Card, Form, Button, Space } from "antd";
import { useJsqel } from "../../api/jsqel";
import { useCommand } from "../../api/cqrs";

import PartieIdentiteForm from "./PartieIdentiteForm";
import PartieReglesForm from "./PartieReglesForm";
import { layout } from "../layout/formLayout";
import SirenAutosuggest from "./SirenAutosuggest";

const { TabPane } = Tabs;

const Partie = () => {
  const { id } = useParams();
  const history = useHistory();
  const [tabActif, setTabActif] = useState("1");

  const [{ results: partie, loading }] = useJsqel("parties/get", {
    sendItNow: !!id,
    id: parseInt(id),
  });

  const update = useCommand("parties/upsert", {}, () =>
    history.push("/parties")
  );

  const remplirParAutosuggest = (donneesApiSiren) => {
    const organisation = donneesApiSiren.nom_raison_sociale;
    const siren = donneesApiSiren.siret;
    const siege = [
      donneesApiSiren.l1_normalisee,
      donneesApiSiren.l2_normalisee,
      donneesApiSiren.l3_normalisee,
      donneesApiSiren.l4_normalisee,
      donneesApiSiren.l5_normalisee,
      donneesApiSiren.l6_normalisee,
      donneesApiSiren.l7_normalisee,
    ].reduce((adresseComplete, elementNormalise) =>
      elementNormalise
        ? adresseComplete + " " + elementNormalise
        : adresseComplete
    );
    form.setFieldsValue({ organisation, siege, siren });
    setTabActif("1");
  };

  const save = (values) => {
    update({
      nom: values.nom,
      organisation: values.organisation,
      siege: values.siege,
      siren: values.siren,
      valeurs_parametres_regles:
        values.valeurs_parametres_regles &&
        values.valeurs_parametres_regles.length
          ? values.valeurs_parametres_regles
          : [],
    });
  };
  console.log(partie);
  const [form] = Form.useForm();

  return (
    <Card loading={loading}>
      <Form
        name="partie-identite"
        form={form}
        initialValues={partie}
        onFinish={save}
        {...layout}
      >
        <Tabs
          defaultActiveKey="1"
          activeKey={tabActif}
          onChange={(activeKey) => setTabActif(activeKey)}
          style={{ minHeight: "50vh" }}
        >
          <TabPane tab="Identité" key="1">
            <PartieIdentiteForm />
          </TabPane>
          <TabPane tab="Règles applicables par défaut" key="2">
            <PartieReglesForm form={form} />
          </TabPane>
          <TabPane tab="Recherche répertoire Sirene" key="3">
            <SirenAutosuggest onSelect={remplirParAutosuggest} />
          </TabPane>
        </Tabs>
        {(tabActif === "1" || tabActif === "2") && (
          <Form.Item>
            <Space>
              <Button type="primary" htmlType="submit">
                Enregistrer
              </Button>
              <Button onClick={() => history.push("/parties")}>Annuler</Button>
            </Space>
          </Form.Item>
        )}
      </Form>
    </Card>
  );
};
export default Partie;
