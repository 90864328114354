import React from "react";

const CarteDesFaits = ({ faits, date }) => {
  const listeDesFaits = faits.filter((f) => f.date === date);
  return (
    <div>
      {listeDesFaits.map((fait) => (
        <p key={fait.date + fait.message} style={{ margin: 0 }}>
          {fait.message}
        </p>
      ))}
    </div>
  );
};

export default CarteDesFaits;
