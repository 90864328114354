import React, { useState } from "react";
import { Table, Switch, Row, Col } from "antd";
import CarteDesFaits from "../chronologie/CarteDesFaits";
import IconeVariation from "./IconeVariation";
import ExportCSV from "../excel/ExportCSV";

const colonnesResultat = (nbChiffres = 2) => [
  {
    title: "Date de début",
    dataIndex: "date",
  },
  {
    title: "Date de fin",
    dataIndex: "dateFin",
  },
  {
    title: "Nb de jours",
    dataIndex: "nbJoursEcoules",
  },
  {
    title: "Principal",
    dataIndex: "compte",
    render: (value, record) => (
      <IconeVariation
        valeurCourante={value.principal}
        valeurPrecedente={record.precedent.compte.principal}
        nbChiffres={nbChiffres}
      />
    ),
    export: (record) => record.compte.principal.toFixed(nbChiffres),
  },
  {
    title: "Intérêts capitalisés",
    dataIndex: "compte",
    render: (value, record) => (
      <IconeVariation
        valeurCourante={value.interets_capitalises}
        valeurPrecedente={record.precedent.compte.interets_capitalises}
        nbChiffres={nbChiffres}
      />
    ),
    export: (record) => record.compte.interets_capitalises.toFixed(nbChiffres),
  },
  {
    title: "Intérêts",
    dataIndex: "compte",
    render: (value) => value.interets.toFixed(nbChiffres),
    export: (record) => record.compte.interets.toFixed(nbChiffres),
  },
  {
    title: "Intérêts journaliers",
    dataIndex: "compte",
    render: (value, record) => (
      <IconeVariation
        valeurCourante={value.variation}
        valeurPrecedente={record.precedent.compte.variation}
        nbChiffres={nbChiffres}
      />
    ),
    export: (record) => record.compte.variation.toFixed(nbChiffres),
  },
];

const colonnesResultatAvance = (nbChiffres = 2) => [
  ...colonnesResultat(nbChiffres),
  {
    title: "variation_variation",
    dataIndex: "compte",
    render: (value) => value.variation_variation.toFixed(nbChiffres),
    export: (record) => record.compte.variation_variation.toFixed(nbChiffres),
  },
];

const ResultatSimulation = ({
  resultatsSimulationReduitsAvecNbJoursEcoules,
  resultatsAvecChronologie,
  chronologie,
}) => {
  const [modeDetaille, setModeDetaille] = useState(false);
  const nbChiffres = modeDetaille ? 10 : 2;

  return (
    <>
      <Row
        align="middle"
        justify="space-between"
        gutter={16}
        style={{ marginBottom: "0.5rem" }}
      >
        <Col>
          Basculer en mode avancé : &nbsp;
          <Switch
            checked={modeDetaille}
            onChange={(checked) => setModeDetaille(checked)}
          />
        </Col>
        <Col>
          <ExportCSV
            dataSource={
              modeDetaille
                ? resultatsAvecChronologie
                : resultatsSimulationReduitsAvecNbJoursEcoules
            }
            columns={
              modeDetaille
                ? colonnesResultatAvance(nbChiffres)
                : colonnesResultat(nbChiffres)
            }
            libelle="Exporter sous Excel"
          />
        </Col>
      </Row>

      <Table
        size="small"
        id="detail"
        dataSource={
          modeDetaille
            ? resultatsAvecChronologie
            : resultatsSimulationReduitsAvecNbJoursEcoules
        }
        columns={
          modeDetaille
            ? colonnesResultatAvance(nbChiffres)
            : colonnesResultat(nbChiffres)
        }
        rowKey="id"
        expandable={{
          expandedRowRender: (record) => (
            <CarteDesFaits date={record.date} faits={chronologie} />
          ),
          rowExpandable: (record) => !!record.presenceMessageChronologie,
        }}
      />
    </>
  );
};

export default ResultatSimulation;
