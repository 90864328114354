import moment from "moment";
const ordonnerEvenementParDate = (evenement1, evenement2) => {
  const date1 = evenement1.date_evenement;
  const date2 = evenement2.date_evenement;
  if (moment.isMoment(date1)) {
    if (moment.isMoment(date2)) {
      if (date1.isBefore(date2)) return -1;
      else return 1;
    }
    return 1;
  }
  return 1;
};

export default ordonnerEvenementParDate;
