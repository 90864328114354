import React from "react";
import { List, Card } from "antd";
import Parametre from "../parametres/Parametre";

/*
la prop parametres est une structure de données de la forme :
[
  {
    "id":115,
    "simulation_id":27,
    "partie_id":null,
    "parametre_id":5,
    "regle_id":11,
    "valeur":"365",
    "generalisable":false,
    "regles":{
      "id":11,
      "type_evenement_id":2,
      "libelle":
      "Capitalisation des intérêts échus (contractuel)",
      "nom_fonction":"capitalisation_date_anniversaire",
      "debut":null,"fin":null,"priorite":1,"texte_id":38,"type_creance_id":2
      },
    "parametres":{
      "id":5,"libelle":"Nombre de jours diviseurs par an","est_un_taux":false,"valeur_par_defaut":"365","choix":[]
      }
    },
*/

const ParametreRegle = ({ parametres, libelle, editable, supprimable }) => {
  if (parametres && Array.isArray(parametres) && parametres.length) {
    return (
      <Card title={libelle} style={{ marginBottom: "1rem" }}>
        <List
          itemLayout="horizontal"
          size="small"
          dataSource={parametres}
          renderItem={(p) => (
            <Parametre
              nature="regle"
              libelle={p.parametres.libelle}
              valeur={p.valeur}
              id={p.id}
              editable={editable}
              supprimable={supprimable}
              parametre={p.parametres}
              key={p.id}
            />
          )}
        />
      </Card>
    );
  }
  return null;
};

export default ParametreRegle;
