import React, { useState } from "react";
import { Input, List, Button, Space } from "antd";
import { CopyOutlined } from "@ant-design/icons";

const formatterDate = (dateTexte) => {
  if (!dateTexte || typeof adresseTexte !== "string" || dateTexte.length !== 8)
    return dateTexte;
  const annee = dateTexte.slice(0, 4);
  const mois = dateTexte.slice(5, 6);
  const jour = dateTexte.slice(6, 7);
  return jour + "/" + mois + "/" + annee;
};

const SirenInputSearch = ({ onSearch, loading }) => (
  <Space>
    <Input.Search
      placeholder="Nombre de l'entreprise"
      loading={loading}
      onSearch={onSearch("full_text")}
      enterButton
    />
    <Input.Search
      placeholder="SIREN"
      loading={loading}
      onSearch={onSearch("siren")}
      enterButton
    />
    <Input.Search
      placeholder="SIRET"
      loading={loading}
      onSearch={onSearch("siret")}
      enterButton
    />
  </Space>
);

const SirenAutosuggest = ({ onSelect }) => {
  const [resultats, setResultats] = useState([]);
  const [loading, setLoading] = useState(false);
  const [nbResultats, setNbResultats] = useState(0);
  const onSearch = (api) => async (texteRecherche) => {
    // Utilisation de l'API Etalab
    // https://github.com/etalab/sirene_as_api
    const apiEtalab =
      `https://entreprise.data.gouv.fr/api/sirene/v1/${api}/` + texteRecherche;
    setLoading(true);
    await fetch(apiEtalab)
      .then((response) => response.json())
      .then((listeDesEntreprises) => {
        if (api === "full_text") {
          setResultats(listeDesEntreprises.etablissement);
          setNbResultats(listeDesEntreprises.total_results || 0);
          return;
        }

        if (api === "siret") {
          setResultats([listeDesEntreprises.etablissement]);
          setNbResultats(1);
          return;
        }

        if (api === "siren") {
          setResultats([listeDesEntreprises.siege_social]);
          setNbResultats(1);
          return;
        }

        setResultats([]);
        setNbResultats(0);
      });
    setLoading(false);
  };

  console.log("resultats:", resultats);

  return (
    <List
      header={<SirenInputSearch loading={loading} onSearch={onSearch} />}
      footer={
        <div>
          <small> {nbResultats} résultat(s)</small>
        </div>
      }
      bordered
      itemLayout="vertical"
      dataSource={resultats}
      renderItem={(item) => (
        <List.Item key={item.siret}>
          <List.Item.Meta
            avatar={
              <Button onClick={() => onSelect(item)} icon={<CopyOutlined />} />
            }
            title={"SIRET " + item.siret}
            description={item.nom_raison_sociale}
          />
          <div>
            {item.libelle_activite_principale}
            <br />
            {item.libelle_nature_juridique_entreprise}
            <br />
            {item.categorie_entreprise}
            <br />
            {formatterDate(item.date_creation)}
            <br />
            {item.date_debut_activite}
            <br />
            Siège social : {item.is_siege ? "OUI" : "NON"}
            <br />
            Adresse :{" "}
            <Space>
              {item.l1_normalisee}
              {item.l2_normalisee}
              {item.l3_normalisee}
              {item.l4_normalisee}
              {item.l5_normalisee}
              {item.l6_normalisee}
              {item.l7_normalisee}
            </Space>
          </div>
        </List.Item>
      )}
    />
  );
};

export default SirenAutosuggest;
