import React from "react";
import { Table } from "antd";
import { useQuery } from "../../api/cqrs";

const DetailTaux = ({ idTaux }) => {
  const valeurs = useQuery("taux/valeur_taux", { idTaux });

  const columns = [
    {
      title: "Début",
      dataIndex: "debut",
      render: (value) => new Date(value).toLocaleDateString("fr-FR"),
    },
    {
      title: "Fin",
      dataIndex: "fin",
      render: (value) => new Date(value).toLocaleDateString("fr-FR"),
    },
    { title: "Valeur (%)", dataIndex: "valeur" },
  ];

  return (
    <Table
      dataSource={valeurs.results}
      columns={columns}
      rowKey="debut"
      size="small"
    />
  );
};

export default DetailTaux;
