import { useJsqel, useCallback } from "./jsqel";
import { useEffect } from "react";

const eventBus = {
  on(event, callback, callbackWithoutPayload = false) {
    console.log(`Subscribe to event ${event} `);
    document.addEventListener(event, (e) =>
      callbackWithoutPayload ? callback() : callback(e.detail)
    );
  },
  dispatch(event, data) {
    console.log(`Dispatch event ${event} `);
    document.dispatchEvent(new CustomEvent(event, { detail: data }));
  },
  remove(event, callback) {
    console.log(`Unsubscribe event ${event} `);
    document.removeEventListener(event, callback);
  },
};

const useQuery = (nameOfQuery, payload, events = []) => {
  const [state, refresh] = useJsqel(nameOfQuery, {
    ...payload,
    sendItNow: true,
  });
  useEffect(() => {
    // subscribe
    events.forEach((event) => eventBus.on(event, refresh, true));
    return () => events.forEach((event) => eventBus.remove(event, refresh));
  }, []);
  return state;
};

const useCommand = (nameOfCommand, payload, doAfter) => {
  const callback = ({ results, error }) => {
    console.log("useCommand callback :", results);
    if (error) {
      eventBus.dispatch("JSQEL_ERROR", error);
    } else {
      // the results should be an array of {event:<string>, detail:<object>}
      if (results && Array.isArray(results)) {
        results.forEach(
          (result) =>
            result.event && eventBus.dispatch(result.event, result.detail)
        );
      }
      if (results && typeof results === "object" && results.event) {
        results.event && eventBus.dispatch(results.event, results.detail);
      }
      // At least send the JSQEL_SUCCESS event
      eventBus.dispatch("JSQEL_SUCCESS");
      // do something after if needed (usually local derived action)
      doAfter && doAfter();
    }
  };
  const [, refresh] = useJsqel(nameOfCommand, {
    ...payload,
    sendItNow: false,
    callback,
  });
  return refresh;
};

const useEvent = (callback, events = []) => {
  useEffect(() => {
    // subscribe
    events.forEach((event) => eventBus.on(event, callback));
    return () => events.forEach((event) => eventBus.remove(event, callback));
  }, []);
};

export { useQuery, useCommand, useEvent };
