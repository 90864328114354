import React, { useState } from "react";
import { Form, Modal, Button, Input } from "antd";
import { useCommand } from "../../api/cqrs";
import { layout } from "../layout/formLayout";

const NouvelleSimulation = ({ refresh, dossier }) => {
  const [visible, setVisible] = useState(false);
  const create = useCommand("dossiers/add_simulation", {}, () =>
    setVisible(false)
  );

  return (
    <>
      <Button onClick={() => setVisible(true)} type="primary" shape="round">
        Nouvelle simulation
      </Button>
      <Modal
        title="Nouvelle simulation"
        visible={visible}
        onCancel={() => setVisible(false)}
        footer={null}
      >
        <Form
          name="ajout_dossier"
          onFinish={(values) => create({ ...values, dossier_id: dossier.id })}
          {...layout}
        >
          <Form.Item name="libelle" label="Nom de la simulation">
            <Input />
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit">
              Ajouter
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default NouvelleSimulation;
