import React from "react";
import { Row, Col, Card, Divider } from "antd";
import syntheseHistoriqueCreance from "./syntheseHistoriqueCreance";
import syntheseHistoriquePaiement from "./syntheseHistoriquePaiement";
import TableCreances from "./TableCreances";
import TablePaiements from "./TablePaiements";
import ReglesGeneriques from "./ReglesGeneriques";
import ReglesSpecifiques from "./ReglesSpecifiques";
import Titre from "./Titre";

const Restitution = ({ simulation, resultats, chronologie, synthese }) => {
  if (!synthese || !simulation || !resultats) return null;

  const total =
    synthese.compte.principal +
    synthese.compte.interets +
    synthese.compte.interets_capitalises;
  const dernierIndice = resultats.length - 1;
  const nbJoursEcoule = resultats[dernierIndice].indexJour;
  const premierJour = resultats[0].date;
  const dernierJour = resultats[dernierIndice].date;

  const listeDesCreances = simulation.evenements
    .filter((evt) => evt.type_evenements.categorie_id === 1 && evt.est_actif)
    .map((p) =>
      syntheseHistoriqueCreance({
        idEvenementCreance: p.id,
        simulation,
        resultats,
        chronologie,
      })
    );
  console.log("Liste des créances : ", listeDesCreances);

  const listeDesPaiements = simulation.evenements
    .filter((evt) => evt.type_evenements.categorie_id === 2 && evt.est_actif)
    .map((p) =>
      syntheseHistoriquePaiement({
        idEvenementPaiement: p.id,
        simulation,
        resultats,
        chronologie,
      })
    );
  console.log("Liste des paiements : ", listeDesPaiements);

  const listeDesRegles = simulation.valeurs_parametres_regles;
  console.log("Liste des règles : ", listeDesRegles);

  const valeursParametresReglesSimulation =
    simulation.valeurs_parametres_regles;
  const valeursParametresReglesCreancier =
    simulation.dossiers.creancier.valeurs_parametres_regles;

  return (
    <Card id="restitution">
      <Row gutter={[16, 16]}>
        <Col span={6}>LOGO</Col>
        <Col span={6}>
          Créancier :{" "}
          <b>
            {simulation.dossiers.creancier
              ? simulation.dossiers.creancier.nom
              : "Non précisé"}
          </b>
          <br />
          Débiteur :{" "}
          <b>
            {simulation.dossiers.debiteur
              ? simulation.dossiers.debiteur.nom
              : "Non précisé"}
          </b>
        </Col>
        <Col span={6}>
          Créance actualisée au <b>{dernierJour}</b>
        </Col>
        <Col span={6}>Pièce n°</Col>
      </Row>
      <Titre libelle="1. Synthèse" niveau={1} />
      <Row gutter={[16, 16]}>
        <Col span={6}>
          <b>Solde : {total.toFixed(2)} €</b>
          <br />
          <small>
            (pour mémoire, sous réserve d’actualisation à date de complet
            paiement)
          </small>
        </Col>
        <Col span={6}>
          <b>&gt; Principal :</b> {synthese.compte.principal.toFixed(2)} €<br />
          <b>&gt; Intérêts :</b>{" "}
          {(
            synthese.compte.interets + synthese.compte.interets_capitalises
          ).toFixed(2)}{" "}
          €<br />
          &gt; dont intérêts capitalisés{" "}
          {synthese.compte.interets_capitalises.toFixed(2)} €
        </Col>
        <Col span={12}></Col>
      </Row>
      <Row gutter={[16, 16]}>
        <Col>
          <b>Ancienneté : {nbJoursEcoule} jours </b> depuis le {premierJour}{" "}
          (date d’exigibilité de la créance la plus ancienne)
        </Col>
      </Row>
      <Titre libelle="2. Résumé des règles appliquées" niveau={1} />
      <Titre libelle="2.1 Règles génériques" niveau={2} />
      <ReglesGeneriques
        valeursParametresReglesSimulation={valeursParametresReglesSimulation}
        valeursParametresReglesCreancier={valeursParametresReglesCreancier}
      />
      <Titre libelle="2.2 Règles spécifiques à chaque créance" niveau={2} />
      <ReglesSpecifiques evenementsDeLaSimulation={simulation.evenements} />
      <Titre libelle="3. Décomposition par créances" niveau={1} />
      <TableCreances creances={listeDesCreances} />
      <Titre libelle="4. Rappel des règlements intervenus" niveau={1} />
      <TablePaiements paiements={listeDesPaiements} />
    </Card>
  );
};

export default Restitution;
