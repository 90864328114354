import React from "react";
import { Alert } from "antd";

const SyntheseSimulation = ({ synthese, nbChiffres }) => {
  console.log("synthese:", synthese);
  const total =
    synthese.compte.principal +
    synthese.compte.interets +
    synthese.compte.interets_capitalises;
  const description = (
    <div>
      Total à la date du {synthese.date} : {total.toFixed(nbChiffres)} €.{" "}
      <ul>
        <li>Principal : {synthese.compte.principal.toFixed(nbChiffres)} €</li>
        <li>
          Intérêts capitalisés :{" "}
          {synthese.compte.interets_capitalises.toFixed(nbChiffres)} €
        </li>
        <li>Intérêts : {synthese.compte.interets.toFixed(nbChiffres)} €</li>
      </ul>
    </div>
  );

  return (
    <Alert
      closable
      style={{ marginBottom: "1rem" }}
      message="La simulation a été exécutée avec succès !"
      description={description}
      type="success"
    />
  );
};
export default SyntheseSimulation;
