import React from "react";
import { Link } from "react-router-dom";
import { useQuery } from "../../api/cqrs";
import { Card, Table, Button, Space } from "antd";

const Parties = () => {
  const parties = useQuery("parties/list", {}, ["JSQEL_SUCCESS"]);

  const colonnesParties = [
    {
      title: "Nom",
      dataIndex: "nom",
    },
    {
      title: "Organisation",
      dataIndex: "organisation",
    },
    {
      title: "Siège",
      dataIndex: "siege",
    },
    {
      title: "Siren",
      dataIndex: "siren",
    },
    {
      title: "Créé le",
      dataIndex: "cree_le",
      render: (value) => value && value.format("DD/MM/YYYY"),
    },
    {
      title: "Action",
      dataIndex: "id",
      render: (value, record) => (
        <Space>
          <Link to={`/partie/${record.id}`}>Voir</Link>
          <Button type="link">Supprimer</Button>
        </Space>
      ),
    },
  ];

  return (
    <Card
      title="Gestion des dossiers"
      extra={<Link to={`/partie`}>Nouveau</Link>}
    >
      <Table
        dataSource={parties.results}
        columns={colonnesParties}
        rowKey="id"
        loading={parties.loading}
      />
    </Card>
  );
};
export default Parties;
