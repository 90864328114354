import React from "react";
import { Timeline, Card } from "antd";
import CarteDesFaits from "./CarteDesFaits";

const Chronologie = ({ resultatsSimulation }) => {
  const faitsMarquants = resultatsSimulation.results.chronologie;
  console.log("Faits marquants : ", faitsMarquants);

  if (!faitsMarquants || !faitsMarquants.length) return null;

  // On regroupe les faits par date
  const lesDatesDesFaits = faitsMarquants.reduce(
    (acc, val) => (acc.includes(val.date) ? acc : [...acc, val.date]),
    []
  );

  return (
    <Card id="chronologie">
      <Timeline
        style={{ marginTop: "1rem", maxWidth: "600px", margin: "auto" }}
      >
        {lesDatesDesFaits.map((dateDuFait) => (
          <Timeline.Item key={dateDuFait}>
            <h3>Le {dateDuFait}</h3>
            <CarteDesFaits faits={faitsMarquants} date={dateDuFait} />
          </Timeline.Item>
        ))}
      </Timeline>
    </Card>
  );
};

export default Chronologie;
