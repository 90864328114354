import React from "react";
import { Table, List, Row, Col } from "antd";

const TablePaiements = ({ paiements }) => {
  console.log("paiements:", paiements);

  const columns = [
    { title: "Libelle", dataIndex: "nom" },
    {
      title: "Date",
      dataIndex: "date",
      render: (value) => value.format("DD/MM/YYYY"),
    },
    {
      title: "Affectation",
      dataIndex: "chronologieEvt",
      render: (value) =>
        value && Array.isArray(value) ? (
          <List
            dataSource={value}
            renderItem={(item) => <List.Item> {item.message} </List.Item>}
            size="small"
            split={false}
          />
        ) : (
          ""
        ),
    },
  ];

  const sommeDesPaiementsForces = paiements
    .filter((p) => p.natureSpontaneOuForce === "Forcé")
    .reduce((acc, val) => acc + parseFloat(val.montant), 0.0);

  const sommeDesPaiementsSpontanes = paiements
    .filter((p) => p.natureSpontaneOuForce === "Spontané")
    .reduce((acc, val) => acc + parseFloat(val.montant), 0.0);

  const totalDespaiements =
    (sommeDesPaiementsSpontanes || 0.0) + (sommeDesPaiementsForces || 0.0);

  return (
    <>
      <Row gutter={[16, 16]}>
        <Col span={6}>
          <b>TOTAL : {totalDespaiements.toFixed(2)} € </b>
        </Col>
        <Col span={6}>
          <b>&gt; spontanés :</b> {sommeDesPaiementsSpontanes.toFixed(2)} €
          <br />
          <b>&gt; forcés :</b> {sommeDesPaiementsForces.toFixed(2)} €<br />
        </Col>
      </Row>
      <Table
        dataSource={paiements}
        columns={columns}
        pagination={false}
        rowKey="nom"
        style={{ marginBottom: "2rem" }}
      />
    </>
  );
};

export default TablePaiements;
