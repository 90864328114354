import React, { useState } from "react";
import { List, Button } from "antd";
import { NumberOutlined } from "@ant-design/icons";
import { useJsqel } from "../../api/jsqel";
import ParametreInput from "./ParametreInput";
import SuppressionConfirmee from "../crud/SuppressionConfirmee";

const Parametre = ({
  libelle,
  valeur,
  id,
  nature,
  parametre,
  editable = true,
  supprimable = false,
}) => {
  const [modeEdition, setModeEdition] = useState(false);
  const updateValeur = () => {
    setModeEdition(false);
  };
  const [, mettreAJour] = useJsqel("parametres/" + nature, {
    sendItNow: false,
    id,
    callback: updateValeur,
  });
  const [valeurModifiee, setValeurModifiee] = useState(valeur);

  const BoutonModification = () => (
    <Button onClick={() => setModeEdition(true)}>Modifier</Button>
  );
  const BoutonEnregistrer = () => (
    <Button onClick={() => mettreAJour({ valeur: valeurModifiee })}>
      Enregistrer
    </Button>
  );
  const BoutonSupprimer = () => (
    <SuppressionConfirmee
      type="link"
      danger
      api={
        nature === "evenement"
          ? "parametres/supprimeParametreEvenement"
          : "parametres/supprimeParametreRegle"
      }
      id={id}
    />
  );

  const actionsPossibles = () => {
    if (!editable) return null;
    if (supprimable) {
      return [
        modeEdition ? <BoutonEnregistrer /> : <BoutonModification />,
        <BoutonSupprimer />,
      ];
    } else {
      return [modeEdition ? <BoutonEnregistrer /> : <BoutonModification />];
    }
  };

  return (
    <List.Item actions={actionsPossibles()} key={`list-item-${id}`}>
      <List.Item.Meta
        avatar={<NumberOutlined />}
        title={libelle}
        description={
          <ParametreInput
            modeEdition={modeEdition}
            value={valeurModifiee}
            onChange={(value) => setValeurModifiee(value)}
            parametre={parametre}
            key={`valeur-${id}`}
          />
        }
      />
    </List.Item>
  );
};

export default Parametre;
