import React from "react";
import { Form, Input } from "antd";

const PartieIdentiteForm = () => (
  <>
    <Form.Item name="nom" label="Nom">
      <Input />
    </Form.Item>
    <Form.Item name="organisation" label="Organisation">
      <Input />
    </Form.Item>
    <Form.Item name="siege" label="Siege">
      <Input />
    </Form.Item>
    <Form.Item name="siren" label="SIREN">
      <Input />
    </Form.Item>
  </>
);

export default PartieIdentiteForm;
