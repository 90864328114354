import React from "react";
import { Table } from "antd";
import fomatValeurMonetaire from "./fomatValeurMonetaire";
import moment from "moment";

const toMoment = (truc) => {
  if (typeof truc === "string") return moment(truc, "DD/MM/YYYY");
  return moment(truc);
};

const calculDureeCreance = (debut, fin, solde) => {
  if (!debut) return "-";
  if (!fin && !solde) return "-";
  const dateDebut = toMoment(debut);
  if (solde) {
    // Date du solde de la créance présente -> la créance a été acquitée
    const dateSolde = toMoment(solde);
    return dateSolde.diff(dateDebut, "days");
  } else {
    const dateFin = toMoment(fin);
    return dateFin.diff(dateDebut, "days");
  }
};

const formatterAffichageTaux = (valeur) => {
  if (!valeur || valeur === "0") {
    return "";
  }
  if (Array.isArray(valeur)) {
    return `Entre ${fomatValeurMonetaire(valeur[0])} et ${fomatValeurMonetaire(
      valeur[1]
    )} %`;
  }
  return `${fomatValeurMonetaire(valeur)} %`;
};

const TableCreances = ({ creances }) => {
  const columns = [
    { title: "Libelle", dataIndex: "nom" },
    {
      title: "Date",
      dataIndex: "date",
      render: (value) => value.format("DD/MM/YYYY"),
    },
    {
      title: "Montant",
      dataIndex: "compteDebutPrincipal",
      render: (value) => fomatValeurMonetaire(value && value.principal),
    },
    {
      title: "Point de départ",
      dataIndex: "compteDebutInteret",
      render: (value) => value && value.date,
    },
    {
      title: "Taux",
      dataIndex: "taux",
      render: (value) => formatterAffichageTaux(value),
    },
    {
      title: "Acquitée le",
      dataIndex: "compteSoldeInterets",
      render: (value) => (value ? value.date : "Non soldée"),
    },
    {
      title: "Jours de retard",
      dataIndex: "compteSoldeInterets",
      render: (value, record) =>
        calculDureeCreance(
          record.compteDebutInteret?.date,
          record.compteFin?.date,
          value && value.date
        ),
    },
    {
      title: "Solde principal",
      dataIndex: "compteFin",
      render: (value) => fomatValeurMonetaire(value && value.principal),
    },
    {
      title: "Solde intérêts",
      dataIndex: "compteFin",
      render: (value) =>
        fomatValeurMonetaire(
          value && (value.interets || 0) + (value.interets_capitalises || 0)
        ),
    },
    {
      title: "Solde total",
      dataIndex: "compteFin",
      render: (value) =>
        fomatValeurMonetaire(
          value &&
            (value.principal || 0) +
              (value.interets || 0) +
              (value.interets_capitalises || 0)
        ),
    },
  ];

  return (
    <Table
      dataSource={creances}
      columns={columns}
      pagination={false}
      rowKey="nom"
      style={{ marginBottom: "2rem" }}
    />
  );
};

export default TableCreances;
