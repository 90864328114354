import React, { useState, useMemo } from "react";
import { useParams } from "react-router-dom";
import { Card, Spin, Button, Tabs, Timeline, Space } from "antd";
import { useJsqel } from "../../api/jsqel";
import { useQuery } from "../../api/cqrs";

import Evenement from "../evenements/Evenements";
import ParametresRegles from "../regles/ParametresRegles";
import ResultatSimulation from "../resultats/ResultatSimulation";
import AjoutEditionEvenement from "../evenements/AjoutEditionEvenement";
import ordonnerEvenementParDate from "../evenements/ordonnerEvenementParDate";
import Chronologie from "../chronologie/Chronologie";
import Restitution from "../restitution/Restitution";
import ErreurSimulation from "./ErreurSimulation";
import SyntheseSimulation from "./SyntheseSimulation";
import postTraitementsResultats from "../resultats/postTraitementsResultats";
import BoutonImprimer from "../impression/BoutonImprimer";
import EcranAttente from "./EcranAttente";

const pleineHauteur = { minHeight: "75vh" };

const Simulation = () => {
  let { simulationId } = useParams();
  simulationId = parseInt(simulationId);

  const [activeTab, setActiveTab] = useState("evenements");

  const [nombreSimulationsRealisees, setNombreSimulationsRealisees] = useState(
    0
  );

  const simulations = useQuery(
    "simulations/get",
    { simulationId: simulationId },
    ["JSQEL_SUCCESS"]
  );

  const simulation =
    simulations && simulations.results && simulations.results.length
      ? simulations.results[0]
      : null;

  const [resultatsSimulation, lanceSimulation] = useJsqel(
    "simulations/lancer",
    {
      sendItNow: false,
      simulationId,
      callback: () => setNombreSimulationsRealisees((s) => s + 1),
    }
  );

  /*
  const apresModificationParametres = () => {
    refresh();
  };
  */

  console.log("resultatsSimulation:", resultatsSimulation.results);

  const {
    synthese,
    chronologie,
    resultatsSimulationReduits,
    resultatsAvecChronologie,
  } = useMemo(() => postTraitementsResultats(resultatsSimulation.results), [
    resultatsSimulation.results,
  ]);

  if (!simulation) return <Spin />;

  return (
    <Card
      title={simulation.libelle}
      extra={
        <Space>
          <Button
            type="primary"
            onClick={() => lanceSimulation()}
            loading={resultatsSimulation.loading}
          >
            Lancer la simulation
          </Button>
          <BoutonImprimer idCadre={activeTab} />
        </Space>
      }
    >
      <ErreurSimulation
        erreur={resultatsSimulation.results.erreur}
        parametresEvenementManquants={
          resultatsSimulation.results.parametresEvenementManquants
        }
        parametresRegleManquants={
          resultatsSimulation.results.parametresRegleManquants
        }
        simulationId={simulationId}
      />
      <EcranAttente visible={resultatsSimulation.loading} />

      {!resultatsSimulation.results.erreur && synthese && (
        <SyntheseSimulation
          synthese={synthese}
          nbChiffres={2}
          key={nombreSimulationsRealisees}
        />
      )}

      <Tabs
        defaultActiveKey="1"
        style={{ marginBottom: "1rem" }}
        onChange={(key) => setActiveTab(key)}
      >
        <Tabs.TabPane tab="Evénements" key="evenements">
          <Card id="evenements" style={pleineHauteur}>
            <Timeline
              style={{ marginTop: "1rem", maxWidth: "600px", margin: "auto" }}
            >
              {simulation.evenements
                .sort(ordonnerEvenementParDate)
                .map((evenement) => (
                  <Evenement
                    evenement={evenement}
                    key={evenement.id}
                    simulationId={simulationId}
                  />
                ))}
              <AjoutEditionEvenement simulationId={simulationId} />
            </Timeline>
          </Card>
        </Tabs.TabPane>
        <Tabs.TabPane tab="Règles appliquées" key="regles">
          <ParametresRegles
            valeursParametresReglesSimulation={
              simulation.valeurs_parametres_regles
            }
            valeursParametresReglesCreancier={
              simulation.dossiers.creancier.valeurs_parametres_regles
            }
            creancier={simulation.dossiers.creancier}
          />
        </Tabs.TabPane>
        <Tabs.TabPane tab="Chronologie" key="chronologie">
          <Chronologie resultatsSimulation={resultatsSimulation} />
        </Tabs.TabPane>
        <Tabs.TabPane tab="Restitution" key="restitution">
          <Restitution
            simulation={simulation}
            synthese={synthese}
            resultats={resultatsAvecChronologie}
            chronologie={chronologie}
          />
        </Tabs.TabPane>

        <Tabs.TabPane tab="Détail du calcul" key="detail">
          {(resultatsSimulation.results.resultat ||
            resultatsSimulation.loading) && (
            <ResultatSimulation
              resultatsSimulationReduitsAvecNbJoursEcoules={
                resultatsSimulationReduits
              }
              resultatsAvecChronologie={resultatsAvecChronologie}
              chronologie={chronologie}
            />
          )}
        </Tabs.TabPane>
      </Tabs>
    </Card>
  );
};

export default Simulation;
