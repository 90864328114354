import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import { Layout } from "antd";

import UserLoginForm from "../login/UserLoginForm";
import Upload from "../upload/Upload";
import UserList from "../login/UserList";
import Dossiers from "../dossiers/Dossiers";
import Simulation from "../simulations/Simulation";
import SideNavMenu from "./SideNavMenu";
import HeaderContent from "./HeaderContent";
import Parties from "../parties/Parties";
import Partie from "../parties/Partie";
import Taux from "../taux/Taux";

const { Header, Footer, Content, Sider } = Layout;

const PrivateApp = () => {
  return (
    <Layout>
      <Header style={{ padding: 0 }}>
        <HeaderContent />
      </Header>

      <Layout style={{ minHeight: "100vh" }}>
        <Sider collapsible>
          <SideNavMenu />
        </Sider>

        <Content style={{ padding: "1rem" }}>
          <Switch>
            <Route exact path="/dossiers" component={Dossiers} />
            <Route exact path="/parties" component={Parties} />
            <Route exact path="/partie/:id" component={Partie} />
            <Route exact path="/partie" component={Partie} />
            <Route
              exact
              path="/simulations/:simulationId"
              component={Simulation}
            />
            <Route exact path="/taux" component={Taux} />
            <Route exact path="/taux/nouveau" component={Taux} />
            <Route exact path="/users" component={UserList} />
            <Route exact path="/upload" component={Upload} />
            <Redirect exact from="/" to="/dossiers" />
            <Route component={UserLoginForm} />
          </Switch>
        </Content>
      </Layout>

      <Footer style={{ textAlign: "center", height: "45px" }}>
        Scrooge - <a href="https://camilab.co">Camilab 2021</a>{" "}
      </Footer>
    </Layout>
  );
};

export default PrivateApp;
