import React from "react";
import { Button, Alert } from "antd";
import { useCommand } from "../../api/cqrs";

const ErreurSimulation = ({
  erreur,
  parametresEvenementManquants,
  parametresRegleManquants,
  simulationId,
}) => {
  const creerParametresRegles = useCommand(
    "simulations/creerParametresRegles",
    {
      simulationId,
      regle_ids:
        parametresRegleManquants && parametresRegleManquants.map((p) => p.id),
    }
  );

  const creerParametresEvenements = useCommand(
    "simulations/creerParametresEvenements",
    {
      simulationId,
    }
  );

  const texteParametresEvenementManquants = parametresEvenementManquants
    ? parametresEvenementManquants.map((evt) => <li>{evt.libelle}</li>)
    : null;
  const texteParametresRegleManquants = parametresRegleManquants
    ? parametresRegleManquants.map((evt) => <li>{evt.libelle}</li>)
    : null;
  const description = (
    <div>
      {erreur}
      <p>
        <ul>
          {texteParametresEvenementManquants}
          {texteParametresRegleManquants}
        </ul>
      </p>
      <p>
        {parametresRegleManquants && (
          <Button onClick={() => creerParametresRegles()}>
            Créer automatiquement les paramètres manquants des règles
          </Button>
        )}
      </p>
      <p>
        {texteParametresEvenementManquants && (
          <Button onClick={() => creerParametresEvenements()}>
            Créer automatiquement les paramètres manquants des évenements
          </Button>
        )}
      </p>
    </div>
  );

  if (erreur) {
    return (
      <Alert
        message="Erreur"
        description={description}
        type="error"
        showIcon
        style={{ marginBottom: "1rem" }}
      />
    );
  }

  return null;
};

export default ErreurSimulation;
