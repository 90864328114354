import React from "react";
import { Link } from "react-router-dom";
import { Typography, Card } from "antd";
import ParametreRegle from "./ParametreRegle";

const pleineHauteur = { minHeight: "75vh" };

const ParametresRegles = ({
  valeursParametresReglesSimulation,
  valeursParametresReglesCreancier,
  creancier,
}) => {
  console.log(
    "valeursParametresReglesCreancier:",
    valeursParametresReglesCreancier
  );
  const valeursParametresRegles = [
    ...valeursParametresReglesSimulation,
    ...valeursParametresReglesCreancier,
  ];

  const listeDesRegles = valeursParametresRegles.reduce(
    (acc, val) =>
      acc.includes(val.regles.libelle) ? acc : [...acc, val.regles.libelle],
    []
  );

  if (listeDesRegles.length === 0)
    return (
      <Card id="regles" style={pleineHauteur} bordered={false}>
        <p>Aucun paramètre</p>
      </Card>
    );

  return (
    <Card id="regles" style={pleineHauteur} bordered={false}>
      <Typography.Title level={3}>
        Règles spécifiques à cette simulation
      </Typography.Title>
      {valeursParametresReglesSimulation.length === 0 ? "Aucune" : null}
      {listeDesRegles.map((libelleRegle) => (
        <ParametreRegle
          libelle={libelleRegle}
          parametres={valeursParametresReglesSimulation.filter(
            (valeurParametre) => valeurParametre.regles.libelle === libelleRegle
          )}
          supprimable={true}
          key={libelleRegle}
        />
      ))}
      <Typography.Title level={3}>
        Règles héritées du cadre du créancier
      </Typography.Title>
      <p>
        <Link to={`/partie/${creancier.id}`}>
          Consulter le profil du créancier{" "}
        </Link>{" "}
      </p>
      {valeursParametresReglesCreancier.length === 0 ? "Aucune" : null}
      {listeDesRegles.map((libelleRegle) => (
        <ParametreRegle
          libelle={libelleRegle}
          parametres={valeursParametresReglesCreancier.filter(
            (valeurParametre) => valeurParametre.regles.libelle === libelleRegle
          )}
          editable={false}
          key={libelleRegle}
        />
      ))}
    </Card>
  );
};

export default ParametresRegles;
