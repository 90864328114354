import React, { useState } from "react";
import { Form, Modal, Button, Input } from "antd";
import { useCommand } from "../../api/cqrs";
import { layout } from "../layout/formLayout";

const ClonerSimulation = ({ refresh, simulation_id }) => {
  const [visible, setVisible] = useState(false);
  const cloner = useCommand("simulations/clone_simulation", {}, () =>
    setVisible(false)
  );

  return (
    <>
      <Button onClick={() => setVisible(true)} type="link">
        Dupliquer
      </Button>
      <Modal
        title="Dupliquer la simulation"
        visible={visible}
        onCancel={() => setVisible(false)}
        footer={null}
      >
        <Form
          name="cloner_simulation"
          onFinish={(values) => cloner({ ...values, simulation_id })}
          {...layout}
        >
          <Form.Item name="libelle" label="Nom de la simulation">
            <Input />
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit">
              Ajouter
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default ClonerSimulation;
