import React, { useState } from "react";
import {
  Form,
  DatePicker,
  Button,
  Modal,
  Select,
  Timeline,
  Input,
  InputNumber,
} from "antd";
import { PlusCircleOutlined, EditOutlined } from "@ant-design/icons";
import { useQuery, useCommand } from "../../api/cqrs";
import locale from "antd/es/date-picker/locale/fr_FR";
import ParametreInput from "../parametres/ParametreInput";

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};

const AjoutEditionEvenement = ({ simulationId, evenement }) => {
  const [visible, setVisible] = useState(false);

  const { results: natures } = useQuery("evenements/list_type_evt");
  const create = useCommand("evenements/add_evt", {}, () => setVisible(false));
  const edit = useCommand("evenements/edit_evt", {}, () => setVisible(false));

  const modeEdition = !!(evenement && evenement.id);
  const [ParametresEvt, setParametresEvt] = useState([]);

  const options = natures.map((e) => ({ label: e.libelle, value: e.id }));

  const enregistreEvt = (values) => {
    if (modeEdition) {
      edit({ ...values, simulation_id: simulationId, id: evenement.id });
    } else {
      create({ ...values, simulation_id: simulationId });
    }
  };

  const updateListeParametres = (changedValues, allValues) => {
    if ("type_evenement_id" in allValues) {
      const leTypeEvt = natures.find(
        (n) => n.id === allValues.type_evenement_id
      );
      if (leTypeEvt) {
        setParametresEvt(leTypeEvt.parametres);
      }
    }
  };

  return (
    <>
      {modeEdition ? (
        <Button
          icon={<EditOutlined />}
          type="link"
          onClick={() => setVisible(true)}
        />
      ) : (
        <Timeline.Item>
          <Button
            icon={<PlusCircleOutlined />}
            onClick={() => setVisible(true)}
          >
            Ajouter un évenement
          </Button>
        </Timeline.Item>
      )}

      <Modal
        title={modeEdition ? "Editer un évenement " : "Ajouter un évenement "}
        visible={visible}
        footer={null}
        onCancel={() => setVisible(false)}
        width="60%"
      >
        <Form
          name="ajout_evt"
          onFinish={enregistreEvt}
          initialValues={evenement}
          onValuesChange={updateListeParametres}
          {...layout}
        >
          {!modeEdition && (
            <Form.Item
              name="type_evenement_id"
              label="Nature"
              rules={[{ required: true, message: "La nature est obligatoire" }]}
            >
              <Select placeholder="Nature de l'évènement" options={options} />
            </Form.Item>
          )}
          <Form.Item
            name="date_evenement"
            label="Date"
            rules={[{ required: true, message: "La date est obligatoire" }]}
          >
            <DatePicker
              locale={locale}
              mode="date"
              picker="date"
              format="DD-MM-YYYY"
            />
          </Form.Item>
          <Form.Item
            name="nom"
            label="Nom de l'événement"
            rules={[
              { required: true, message: "Vous devez lui donner un nom" },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="numero"
            label="Numéro de l'événement"
            rules={[
              { required: true, message: "Vous devez lui donner un numéro" },
            ]}
          >
            <InputNumber />
          </Form.Item>
          {!modeEdition &&
            ParametresEvt.map((p) => (
              <Form.Item
                name={["parametres", p.id.toString()]}
                label={p.libelle}
              >
                <ParametreInput parametre={p} modeEdition={true} />
              </Form.Item>
            ))}

          <Form.Item>
            <Button type="primary" htmlType="submit">
              {modeEdition ? "Modifier" : "Ajouter"}
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default AjoutEditionEvenement;
