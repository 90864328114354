import React, { useState } from "react";
import { Select, Input } from "antd";
import { useJsqel } from "../../api/jsqel";

const ParametreInput = ({
  parametre,
  onChange,
  modeEdition,
  value,
  ...rest
}) => {
  const [taux] = useJsqel("taux/liste_taux", { sendItNow: true });
  const options = taux.results.map((t) => ({ value: t.id, label: t.libelle }));
  const libelleCourantTaux = options.find((t) => t.value === parseInt(value));

  if (parametre && parametre.est_un_taux) {
    return modeEdition ? (
      <Select
        onChange={(value) => onChange(value ? value.toString() : "0")}
        options={options}
        style={{ minWidth: "300px" }}
        value={value ? parseInt(value) : null}
        allowClear
      />
    ) : (
      <span>
        {(libelleCourantTaux && libelleCourantTaux.label) || "Non spécifié."}
      </span>
    );
  }

  if (
    parametre &&
    parametre.choix &&
    Array.isArray(parametre.choix) &&
    parametre.choix.length
  ) {
    return modeEdition ? (
      <Select
        options={parametre.choix.map((c) => ({ value: c }))}
        style={{ minWidth: "300px" }}
        value={value}
        onChange={(value) => onChange(value)}
      />
    ) : (
      value
    );
  }
  return modeEdition ? (
    <Input value={value} onChange={(e) => onChange(e.target.value)} />
  ) : (
    value
  );
};

export default ParametreInput;
