import React from "react";
import { List, Timeline, Button, Popconfirm, Space } from "antd";
import { DeleteOutlined } from "@ant-design/icons";
import Parametre from "../parametres/Parametre";
import { useCommand } from "../../api/cqrs";
import AjoutEditionEvenement from "./AjoutEditionEvenement";
import InhiberEvenement from "./InhiberEvenement";

const Evenement = ({ evenement, simulationId, refresh }) => {
  const supprimer = useCommand("evenements/delete_evt", {
    id: evenement.id,
    simulation_id: simulationId,
  });

  const style = evenement.est_actif ? { opacity: "1" } : { opacity: "0.3" };

  const titreEvt = (
    <div>
      <Space>
        <h3>
          {`Le ${evenement.date_evenement.format("DD/MM/YYYY")} : ${
            evenement.nom
          }`}
        </h3>
        <AjoutEditionEvenement
          evenement={evenement}
          simulationId={simulationId}
          refresh={refresh}
        />
        <InhiberEvenement
          evenement={evenement}
          simulationId={simulationId}
          refresh={refresh}
        />
        <Popconfirm
          title="Etes-vous sûr de vouloir supprimer l'événement ?"
          onConfirm={() => supprimer()}
          okText="Oui"
          cancelText="Non"
        >
          <Button type="link" icon={<DeleteOutlined />} />
        </Popconfirm>
      </Space>
      <p>
        {evenement.type_evenements.libelle} #{evenement.numero}{" "}
      </p>
    </div>
  );

  return evenement.valeurs_parametres_evenements &&
    evenement.valeurs_parametres_evenements.length ? (
    <Timeline.Item style={style}>
      {titreEvt}

      <List
        itemLayout="horizontal"
        size="small"
        dataSource={evenement.valeurs_parametres_evenements.sort(
          (e1, e2) => e1.id - e2.id
        )}
        renderItem={(parametre) => (
          <Parametre
            nature="evenement"
            libelle={parametre.parametres.libelle}
            valeur={parametre.valeur}
            id={parametre.id}
            refresh={refresh}
            key={parametre.id}
            parametre={parametre.parametres}
          />
        )}
      />
    </Timeline.Item>
  ) : (
    <Timeline.Item>
      {titreEvt}
      Pas de paramètre.
    </Timeline.Item>
  );
};

export default Evenement;
