import React from "react";
import { Space } from "antd";
import { ArrowUpOutlined, ArrowDownOutlined } from "@ant-design/icons";

const stylesIcones = {
  plus: { color: "green", fontWeight: "bold" },
  moins: { color: "red", fontWeight: "bold" },
};

const IconeVariation = ({ valeurCourante, valeurPrecedente, nbChiffres }) => {
  if (Math.ceil(valeurCourante * 100) > Math.ceil(valeurPrecedente * 100))
    return (
      <Space style={stylesIcones.plus}>
        {valeurCourante.toFixed(nbChiffres)}
        <ArrowUpOutlined />
      </Space>
    );
  if (Math.ceil(valeurCourante * 100) < Math.ceil(valeurPrecedente * 100))
    return (
      <Space style={stylesIcones.moins}>
        {valeurCourante.toFixed(nbChiffres)}
        <ArrowDownOutlined />
      </Space>
    );

  return <span>{valeurCourante.toFixed(nbChiffres)}</span>;
};

export default IconeVariation;
