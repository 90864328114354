import * as moment from "moment";

const postTraitementsResultats = ({ resultat, chronologie }) => {
  if (!resultat || !resultat.length) return {};

  // Synthèse des résultats
  const synthese = resultat && resultat[resultat.length - 1];

  const resultatsAvecChronologie = resultat.map((r, i) => ({
    ...r,
    precedent: i > 0 ? { ...resultat[i - 1] } : { ...r },
    indexJour: i,
    nbJoursEcoules: 1,
    presenceMessageChronologie: chronologie
      .map((fait) => fait.date.replace(/-/gi, "/").trim())
      .includes(r.date), // Boolean qui indique la présence de messages issus du logger pour ce jour
  }));

  // On réduit le nb de lignes pour ne conserver que celles présentant une variation
  const estDifferent = (nb1, nb2) => Math.abs(nb1 - nb2) > 0.0001;
  const resultatsSimulationReduits = resultatsAvecChronologie.filter(
    (r, index) => {
      if (index === 0) return true;
      if (index === resultat.length - 1) return true;
      if (r.presenceMessageChronologie) return true;
      /*
      if (
        estDifferent(r.compte.principal, resultat[index + 1].compte.principal)
      )
        return true;
      if (
        estDifferent(r.compte.variation, resultat[index + 1].compte.variation)
      )
        return true;
        */
      if (
        estDifferent(r.compte.principal, resultat[index - 1].compte.principal)
      )
        return true;
      if (
        estDifferent(r.compte.variation, resultat[index - 1].compte.variation)
      )
        return true;
      return false;
    }
  );

  const resultatsSimulationReduitsAvecNbJoursEcoules = resultatsSimulationReduits.map(
    (r, index) => {
      if (index === resultatsSimulationReduits.length - 1) {
        return { ...r, nbJoursEcoules: 0 };
      } else {
        return {
          ...r,
          nbJoursEcoules:
            resultatsSimulationReduits[index + 1].indexJour - r.indexJour,
        };
      }
    }
  );

  const resultatsSimulationReduitsAvecDateFin = resultatsSimulationReduitsAvecNbJoursEcoules.map(
    (r) => ({
      ...r,
      dateFin: moment(r.date, "DD/MM/YYYY")
        .add(r.nbJoursEcoules > 0 ? r.nbJoursEcoules - 1 : 0, "days")
        .format("DD/MM/YYYY"),
    })
  );

  return {
    synthese,
    resultatsSimulationReduits: resultatsSimulationReduitsAvecDateFin,
    resultatsAvecChronologie,
    chronologie,
  };
};

export default postTraitementsResultats;
