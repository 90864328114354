import React, { useState } from "react";
import { Form, Modal, Button, Input } from "antd";
import { useCommand } from "../../api/cqrs";
import { FormElement } from "../crud/CreateEdit";
import { layout } from "../layout/formLayout";

const EditionDossier = ({ dossier, libelle }) => {
  const [visible, setVisible] = useState(false);
  const create = useCommand("dossiers/upsert_dossier", {}, () =>
    setVisible(false)
  );

  return (
    <>
      <Button onClick={() => setVisible(true)} type="link">
        {libelle}
      </Button>
      <Modal
        title="Nouveau dossier"
        visible={visible}
        onCancel={() => setVisible(false)}
        footer={null}
      >
        <Form
          name={
            dossier && dossier.id
              ? "modification_dossier_" + dossier.id
              : "ajout_dossier"
          }
          onFinish={(values) => create(values)}
          {...layout}
          initialValues={dossier}
        >
          <Form.Item name="id"></Form.Item>
          <Form.Item name="libelle" label="Nom du dossier">
            <Input />
          </Form.Item>
          <FormElement
            name="type_creance_id"
            label="Type de créance"
            type={{
              ressource: "type_creances",
              api: "generic_crud",
              field: "libelle",
            }}
            validation={[
              { required: true, message: "Le type de créance est requis" },
            ]}
          />

          <FormElement
            name="creancier_id"
            label="Créancier"
            type={{ ressource: "parties", api: "parties", field: "nom" }}
            validation={[
              { required: true, message: "Le créancier est requis" },
            ]}
          />
          <FormElement
            name="debiteur_id"
            label="Débiteur"
            type={{ ressource: "parties", api: "parties", field: "nom" }}
          />

          <Form.Item>
            <Button type="primary" htmlType="submit">
              Enregistrer
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default EditionDossier;
