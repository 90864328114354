import React, { useState } from "react";
import { Form, Modal, Button, Input } from "antd";
import { useCommand } from "../../api/cqrs";
import { layout } from "../layout/formLayout";

const EditionSimulation = ({ simulation }) => {
  const [visible, setVisible] = useState(false);
  const update = useCommand("simulations/update", {}, () => setVisible(false));

  return (
    <>
      <Button onClick={() => setVisible(true)} type="link">
        Modifier
      </Button>
      <Modal
        title="Simulation"
        visible={visible}
        onCancel={() => setVisible(false)}
        footer={null}
      >
        <Form
          name={"modification_simulation_" + simulation.id}
          initialValues={simulation}
          onFinish={(values) => update({ data: { ...simulation, ...values } })}
          {...layout}
        >
          <Form.Item name="libelle" label="Nom de la simulation">
            <Input />
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit">
              Modifier
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default EditionSimulation;
