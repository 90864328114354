// A partir d'un n° d'evt d'un paiement et d'un résultat de simulation, retourne :
// - le nom de l'evt
// - le numéro de l'evt
// - la date de l'evt
// - les différentes créances remboursées par ce paiement

const ID_PARAMETRE_SPONTANE_OU_FORCE = 11;
const ID_MONTANT = 3;

const syntheseHistoriquePaiement = ({
  idEvenementPaiement,
  simulation,
  resultats,
  chronologie,
}) => {
  const evenement = simulation.evenements
    .filter((evt) => evt.est_actif)
    .find((evt) => evt.id === idEvenementPaiement);

  if (!evenement) {
    throw new Error("Evenement introuvable id=" + idEvenementPaiement);
  }

  const { nom, numero, date_evenement: date } = evenement;
  console.log("evenement:", evenement);

  // recherche de la nature spontanée ou forcée
  const natureSpontaneOuForce = evenement.valeurs_parametres_evenements.find(
    (e) => e.parametre_id === ID_PARAMETRE_SPONTANE_OU_FORCE
  );
  const montant = evenement.valeurs_parametres_evenements.find(
    (e) => e.parametre_id === ID_MONTANT
  );

  console.log("syntheseHistoriquePaiement:", chronologie);
  // On recherche dans la chronologie tout ce qui concerne cet événement
  const chronologieEvt = chronologie.filter(
    (c) => c.evenement === idEvenementPaiement
  );

  return {
    nom,
    numero,
    date,
    chronologieEvt,
    natureSpontaneOuForce: natureSpontaneOuForce?.valeur,
    montant: montant?.valeur,
  };
};

export default syntheseHistoriquePaiement;
