import React from "react";
import { Select, Input, Form, Button, InputNumber, Modal } from "antd";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { useQuery, useCommand } from "../../api/cqrs";

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 4 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 20 },
  },
};
const formItemLayoutWithOutLabel = {
  wrapperCol: {
    xs: { span: 24, offset: 0 },
    sm: { span: 20, offset: 4 },
  },
};

const obligatoire = [
  {
    required: true,
    message: "Valeur requise",
  },
];

const EditionFormule = ({ record, onFinish }) => {
  const taux = useQuery("taux/taux_references");
  const update = useCommand("taux/update_taux_calcule", {});
  const create = useCommand("taux/cree_taux_calcule", {});

  const [form] = Form.useForm();

  const visible = record !== null;
  const valeursInitiales = record &&
    record.id && {
      libelle: record.libelle,
      constante: record.formule.constante,
      termes: record.formule.termes,
      id: record.id,
    };

  const save = (values) => {
    const formule = { termes: values.termes, constante: values.constante };
    if (record && record.id) {
      console.log("Update :", values);
      update({ ...values, formule, id: record.id });
    } else {
      console.log("Create :", values);
      create({ ...values, formule });
    }
  };

  return (
    <Modal
      title="Edition d'un taux calculé par une formule"
      visible={visible}
      width={"50%"}
      onOk={() => form.submit()}
      onCancel={() => onFinish()}
      okText="Enregistrer"
      cancelText="Annuler"
    >
      <Form initialValues={valeursInitiales} onFinish={save} form={form}>
        <Form.Item
          {...formItemLayout}
          name="libelle"
          label="Libellé"
          rules={[
            {
              required: true,
              message: "Le nom du taux calculé est obligatoire",
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.List name="termes">
          {(fields, { add, remove }) => (
            <>
              {fields.map((field, index) => (
                <Form.Item
                  {...(index === 0
                    ? formItemLayout
                    : formItemLayoutWithOutLabel)}
                  label={index === 0 ? "Termes" : ""}
                  required={false}
                  key={field.key}
                >
                  <Form.Item
                    {...field}
                    noStyle
                    name={[field.name, "signe"]}
                    rules={obligatoire}
                  >
                    <Select
                      style={{ width: "10%" }}
                      options={[
                        { value: 1, label: "+" },
                        { value: -1, label: "-" },
                      ]}
                    />
                  </Form.Item>{" "}
                  <Form.Item {...field} noStyle name={[field.name, "taux"]}>
                    <Select
                      style={{ width: "60%" }}
                      rules={obligatoire}
                      options={taux.results.map((t) => ({
                        value: t.id,
                        label: t.libelle,
                      }))}
                    />
                  </Form.Item>
                  {" x "}
                  <Form.Item
                    {...field}
                    noStyle
                    name={[field.name, "coefficient"]}
                    rules={obligatoire}
                  >
                    <InputNumber
                      placeholder="Coefficient"
                      style={{ width: "20%" }}
                      precision={2}
                    />
                  </Form.Item>
                  <MinusCircleOutlined
                    style={{ width: "10%" }}
                    className="dynamic-delete-button"
                    onClick={() => remove(field.name)}
                  />
                </Form.Item>
              ))}

              <Form.Item {...formItemLayoutWithOutLabel}>
                <Button
                  type="dashed"
                  onClick={() => add()}
                  style={{ width: "60%" }}
                  icon={<PlusOutlined />}
                >
                  Ajouter un terme
                </Button>
              </Form.Item>
            </>
          )}
        </Form.List>
        <Form.Item {...formItemLayout} name="constante" label="Constante">
          <InputNumber
            style={{ width: "60%" }}
            precision={2}
            defaultValue={0.0}
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default EditionFormule;
