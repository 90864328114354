import React from "react";
import { Form, Input, Select, Spin, Button, Space, Card } from "antd";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { useJsqel } from "../../api/jsqel";
import ParametreInput from "../parametres/ParametreInput";

const PartieReglesForm = ({ form }) => {
  const [{ results: regles, loading }] = useJsqel("regles/list", {
    sendItNow: true,
  });
  console.log("regles:", regles);

  if (loading) return <Spin />;
  // { regle_id: 16, type_parametre: 1, valeur: "20" },
  const optionsRegles = regles.map((regle) => ({
    value: regle.id,
    label: "Créance " + regle.type_creances?.libelle + " - " + regle.libelle,
  }));

  const optionsParametres = (field) => {
    console.log("field:", field);
    const regle_id = form.getFieldValue([
      "valeurs_parametres_regles",
      field.name,
      "regle_id",
    ]);
    if (regle_id) {
      const laRegle = regles.find((r) => r.id === regle_id);
      if (laRegle) {
        return laRegle.parametres.map((parametre) => ({
          value: parametre.id,
          label: parametre.libelle,
        }));
      }
    }
    return [];
  };

  const getParametre = (fieldName) => {
    const idRegle = form.getFieldValue([
      "valeurs_parametres_regles",
      fieldName,
      "regle_id",
    ]);
    const idParametre = form.getFieldValue([
      "valeurs_parametres_regles",
      fieldName,
      "parametre_id",
    ]);
    console.log("idRegle:", idRegle);
    console.log("idParametre:", idParametre);
    console.log("regles:", regles);

    const laRegle = regles.find((r) => r.id === idRegle);
    if (laRegle) {
      return laRegle.parametres.find((p) => p.id === idParametre);
    }
    return {};
  };

  return (
    <Form.List name="valeurs_parametres_regles">
      {(fields, { add, remove }) => (
        <>
          {fields.map((field) => (
            <Card key={"parametres-" + field.key} style={{ marginBottom: 8 }}>
              <Form.Item
                {...field}
                name={[field.name, "regle_id"]}
                fieldKey={[field.fieldKey, "regle_id"]}
                rules={[{ required: true, message: "Sélectionner la règle" }]}
              >
                <Select placeholder="Règle" options={optionsRegles} />
              </Form.Item>
              <Form.Item shouldUpdate>
                {() => (
                  <Form.Item
                    {...field}
                    name={[field.name, "parametre_id"]}
                    fieldKey={[field.fieldKey, "parametre_id"]}
                    rules={[
                      { required: true, message: "Sélectionner le paramètre" },
                    ]}
                    shouldUpdate
                  >
                    <Select
                      placeholder="Paramètre"
                      options={optionsParametres(field)}
                    />
                  </Form.Item>
                )}
              </Form.Item>
              <Space>
                <Form.Item
                  {...field}
                  name={[field.name, "valeur"]}
                  fieldKey={[field.fieldKey, "valeur"]}
                  rules={[
                    {
                      required: true,
                      message: "Renseigner la valeur par défaut",
                    },
                  ]}
                >
                  <ParametreInput
                    modeEdition={true}
                    parametre={getParametre(field.name)}
                  />
                </Form.Item>
                <Form.Item>
                  <Button
                    onClick={() => remove(field.name)}
                    icon={<MinusCircleOutlined />}
                  >
                    {" "}
                    Supprimer
                  </Button>
                </Form.Item>
              </Space>
            </Card>
          ))}
          <Form.Item>
            <Button
              type="dashed"
              onClick={() => add()}
              block
              icon={<PlusOutlined />}
            >
              Ajouter un paramètre
            </Button>
          </Form.Item>
        </>
      )}
    </Form.List>
  );
};
export default PartieReglesForm;
