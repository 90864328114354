// A partir d'un n° d'evt d'une créance et d'un résultat de simulation, retourne :
// - le nom de l'evt
// - le numéro de l'evt
// - la date de l'evt
// - la date à laquelle les interêts son devenus non nuls
// - la date à laquelle le principal et les intérêts sont devenus nuls
// - l'état du principal à la fin de la simulation
// - l'état des intérêts à la fin de la simulation
// - l'état des intérêts capitalisés à la fin de la simulation
// - le taux appliqué (s'il est identique) ou le couple min/max (s'il y en a plusieurs)
// - les différents paiements intervenus pour cette créance

const syntheseHistoriqueCreance = ({
  idEvenementCreance,
  simulation,
  resultats,
}) => {
  const evenement = simulation.evenements
    .filter((evt) => evt.est_actif)
    .find((evt) => evt.id === idEvenementCreance);

  if (!evenement) {
    throw new Error("Evenement introuvable id=" + idEvenementCreance);
  }

  const { nom, numero, date_evenement: date } = evenement;
  let compteDebutInteret, compteSoldeInterets, compteDebutPrincipal;
  let listeDesTaux = [];
  const compteFin = {
    ...resultats[resultats.length - 1].comptes[idEvenementCreance],
    date: resultats[resultats.length - 1].date,
  };

  for (let resultat of resultats) {
    const compteEvenement = resultat.comptes[idEvenementCreance];
    // Recherche du point de départ du principal
    if (!compteDebutPrincipal && compteEvenement.principal > 0) {
      compteDebutPrincipal = { ...compteEvenement, date: resultat.date };
    }
    // Recherche du point de départ des intérêts
    if (!compteDebutInteret && compteEvenement.interets > 0) {
      compteDebutInteret = { ...compteEvenement, date: resultat.date };
    }
    // Recherche du solde
    if (
      !compteSoldeInterets &&
      compteDebutPrincipal &&
      compteEvenement.principal < 1e-10 &&
      compteEvenement.interets < 1e-10 &&
      compteEvenement.interets_capitalises < 1e-10
    ) {
      compteSoldeInterets = { ...compteEvenement, date: resultat.date };
    }
    // Recherche des taux
    if (compteEvenement.taux && !listeDesTaux.includes(compteEvenement.taux)) {
      listeDesTaux.push(compteEvenement.taux);
    }
    // Recherche des chronologies associées
  }

  const taux =
    listeDesTaux && listeDesTaux.length
      ? listeDesTaux.length > 1
        ? [Math.min(...listeDesTaux), Math.max(...listeDesTaux)]
        : listeDesTaux[0]
      : 0;

  return {
    nom,
    numero,
    date,
    compteDebutInteret,
    compteSoldeInterets,
    compteDebutPrincipal,
    compteFin,
    taux,
  };
};

export default syntheseHistoriqueCreance;
