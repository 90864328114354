import get from "lodash.get";

const S1_EN_PREMIER = -1;
const S2_EN_PREMIER = 1;

const stringSorter = (cle) => (record1, record2) => {
  const s1 = get(record1, cle);
  const s2 = get(record2, cle);

  // une chaîne vide est toujours en premier
  console.log(s1, s2);
  if (!s1) {
    return S1_EN_PREMIER;
  }
  if (!s2) {
    return S2_EN_PREMIER;
  }

  if (s1 > s2) {
    return S2_EN_PREMIER;
  } else {
    return S1_EN_PREMIER;
  }
};

export { stringSorter };
