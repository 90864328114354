import React from "react";
import { Button } from "antd";
import { StopOutlined, CheckCircleOutlined } from "@ant-design/icons";
import { useCommand } from "../../api/cqrs";

const InhiberEvenement = ({ evenement, simulationId }) => {
  const inhiber = useCommand("evenements/toggle_evt", {
    simulation_id: simulationId,
    id: evenement.id,
  });

  return (
    <Button
      icon={evenement.est_actif ? <StopOutlined /> : <CheckCircleOutlined />}
      type="link"
      onClick={() => inhiber()}
    />
  );
};

export default InhiberEvenement;
